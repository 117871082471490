import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Context } from "../contexts/Store";
import { accountsConfig } from "../../axiosConfig";

function TopBar() {
    const { state, dispatch } = useContext(Context);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const isStudentList = state.isStudentList;
    const role = state.user_data.role[0];
    const isSideBar = state.isSideBar;
    function showSideMenu() {
        let element = document.getElementById("menu");
        element.classList.toggle("active");
    }

    const [issupportLogout, setSupportLogout] = useState(false);

    const logoutUser = (e) => {
        e.preventDefault();
        accountsConfig
            .post(
                "/authentication/logout/",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    localStorage.clear();
                    window.location = "/";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const supportLogoutModal = () => {
        return (
            <LogoutModalContainer className={issupportLogout ? "active" : ""}>
                <EditProfile
                    onClick={() => {
                        dispatch({
                            type: "UPDATE_SUPPORT_EDIT_PROFILE",
                            isEditProfile: true,
                        });
                        setSupportLogout(!issupportLogout);
                    }}
                >
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-07-2023/Users.svg"
                        alt="Icon"
                    />
                    <Title>Edit Profile</Title>
                </EditProfile>
                <Logout to="/auth/logout/" onClick={(e) => logoutUser(e)}>
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-07-2023/logout_red.svg"
                        alt="Icon"
                    />
                    <Title style={{ color: "#E02B1D" }}>Logout</Title>
                </Logout>
            </LogoutModalContainer>
        );
    };

    return (
        <Head>
            {supportLogoutModal()}
            <Left>
                <ImageContainer>
                    <LogoLink to="">
                        <Logo
                            className="thumb"
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/steyp-white-logo.svg"
                            alt="Steyp-Logo"
                        />
                    </LogoLink>
                </ImageContainer>
                <HeaderIcon
                    onClick={() => {
                        if (role === "support_engineer") {
                            if (window.innerWidth < 769) {
                                dispatch({
                                    type: "UPDATE_SUPPORT_STUDENT_LIST_MENU",
                                    isStudentList: !isStudentList,
                                });
                            }
                        } else {
                            showSideMenu();
                        }
                    }}
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/hamburg-icon.svg"
                    alt="Icon"
                />
            </Left>
            <RightContainer>
                <div>
                    <ProfileWrapper
                        onClick={() => {
                            if (role === "support_engineer") {
                                setSupportLogout(!issupportLogout);
                            }
                        }}
                        className={role === "support_engineer" && "support"}
                    >
                        <ProfileImage>
                            <NameSpan>
                                {state.user_data.name?.charAt(0).toUpperCase()}
                            </NameSpan>
                        </ProfileImage>
                        <Name>
                            <p>{state.user_data?.name}</p>
                        </Name>
                    </ProfileWrapper>
                </div>
            </RightContainer>
        </Head>
    );
}

export default TopBar;

const HeaderIcon = styled.img`
    display: none;
    @media (max-width: 768px) {
        cursor: pointer;
        display: block;
        width: 19px;
    }
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #161619;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 6px 0;
    cursor: pointer;
    img {
        width: 25px;
        display: block;
    }
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;
const Head = styled.header`
    padding: 0 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    background: #fff;
    height: 58px;
    z-index: 200;
    background: #1b1c1f;
    @media (max-width: 768px) {
        height: 60px;
    }
`;
const Left = styled.div`
    display: flex;
    align-items: center;
`;
const ImageContainer = styled.h1`
    width: 34%;
    margin-right: 92px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;
const LogoLink = styled(Link)`
    display: block;
    /* width: 79px; */
`;
const Logo = styled.img`
    display: block;
    width: 100%;
`;
const RightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    div {
        display: flex;
        align-items: center;
        justify-content: right;
    }
`;
const ProfileWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 23px;
    border-left: 1px solid #262c35;
    @media (max-width: 980px) {
        padding-left: 0;
        border-left: unset;
    }
    @media (max-width: 768px) {
        border-radius: 50%;
    }

    &.support {
        cursor: pointer;
    }
`;
const ProfileImage = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 50%;
    font-size: 18px;
    border: 1px solid #fe673a;
    min-width: 34px;
    position: relative;
    @media all and (max-width: 480px) {
        min-width: 30px;
        max-width: 30px;
        max-height: 30px;
    }
`;
const Name = styled.span`
    margin-left: 13px;
    font-family: "gordita_bold";
    @media (max-width: 360px) {
        margin-left: 8px;
    }
    p {
        color: #fff;
        font-size: 13px;
    }
    p::first-letter {
        text-transform: capitalize;
    }
`;
const Polygon = styled.img`
    display: block;
    width: 9px;
    margin-left: 9px;
    @media (max-width: 360px) {
        display: none;
    }
`;
const AlertImage = styled.div`
    margin-right: 15px;
`;
const Alert = styled.img`
    cursor: pointer;
    display: block;
    width: 20px;
    max-height: 19px;
`;
const NameSpan = styled.span`
    color: #fe673a;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    @media all and (max-width: 480px) {
        font-size: 15px;
        display: inline-block;
    }
`;
const LogoutModalContainer = styled.div`
    position: absolute;
    width: 150px;
    right: 18px;
    bottom: -76px;
    background: #161619;
    border: 1px solid #26272a;
    padding: 8px 10px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    justify-content: center;
    display: none;

    &.active {
        display: flex;
        flex-direction: column;
    }
`;
const Logout = styled(Link)`
    display: inline-flex;
    align-items: center;
    padding: 4px 0px;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const Title = styled.span`
    color: var(--steyp-landingpage-paragraph, #707070);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.selected {
        color: #f7684c;
    }
`;
const EditProfile = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 4px 0px;
    border-bottom: 2px solid #1b1c1f;
`;
