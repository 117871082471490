import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import DatePicker from "react-date-picker";
import { getDDMMYY } from "../../helpers/functions";
import { communityConfig } from "../../../axiosConfig";
// import DateModal from "./modals/DateModal";

function CampusFilter({
    setStartDate,
    setActiveClick,
    activeClick,
    setDropdown,
    isDropdown,
    searchWord,
}) {
    const [isSearchClicked, setSearchClicked] = useState(false);
    const [isDateModal, setDateModal] = useState(false);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    // dropDown Toggle===============================
    const [isDropDown, setDropDown] = useState(false);
    const [campusDropDown, setCampusDropDown] = useState(false);

    // secarh filter================================
    const [searchTerm, setSearchTerm] = useState("");

    // featch campus
    const [isCampus, setCampus] = useState([]);

    // user data====================================

    const [CampusName, setCampusName] = useState("");

    const [campusPk, setCampusPk] = useState("");
    const [campusErrorMessage, setCampusErrorMessage] = useState("");

    const wrapperRef = useRef(null);
    const handleModal = () => {
        setDateModal(!isDateModal);
    };
    //modal outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdown(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    const handleFilterDate = (d) => {
        const convertDates = getDDMMYY(d);
        setActiveClick("Selected Date");
        setStartDate(convertDates);
    };
    // campus list api===================================
    useEffect(() => {
        campusSearch();
        if (searchTerm.length === 0) {
            setCampusDropDown(false);
            setCampusPk("");
        }

        if (searchTerm) {
            setCampusDropDown(true);
            setCampusPk("");
            setDropDown(false);
        }
    }, [searchTerm]);

    // campusSearch==========================
    const campusSearch = () => {
        communityConfig
            .get(`campuses/search-campuses/?q=${searchTerm}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCampus(data);

                    if (data.length <= 0) {
                        setCampusDropDown(false);
                        setCampusErrorMessage("Campus not found");
                    } else {
                        setCampusErrorMessage("");
                    }
                } else if (StatusCode === 6001) {
                    setCampusErrorMessage("");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <TopFilter>
                <CoverSet ref={wrapperRef}>
                    <Filter onClick={() => setDropdown(!isDropdown)}>
                        <FilterText>{activeClick}</FilterText>
                        <FIlterImage>
                            <img
                                src={
                                    require("../../../assets/images/bda/Filter.svg")
                                        .default
                                }
                                alt="Image"
                            />
                        </FIlterImage>
                    </Filter>
                    <Popup className={isDropdown ? "dropdown" : ""}>
                        <Date
                            onClick={() => {
                                setSearchClicked(true);
                                setActiveClick("Search");
                            }}
                            className="search"
                        >
                            <input
                                type="text"
                                placeholder="Enter the campus name"
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setCampusName("");
                                }}
                                value={
                                    CampusName.length !== 0
                                        ? CampusName
                                        : searchTerm
                                }
                            ></input>
                            <SearchImage
                                to={`?campus_pk=${campusPk}`}
                                onClick={() => setDropdown(false)}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-05-2022/search.svg"
                                    alt="Search"
                                />
                            </SearchImage>
                        </Date>

                        <GraduationStatus>
                            {!campusPk ? (
                                <>
                                    <Error>{campusErrorMessage} </Error>
                                </>
                            ) : null}
                        </GraduationStatus>
                        <ClassDropDown
                            className={campusDropDown ? "active" : ""}
                        >
                            <UlMini>
                                {isCampus.map((item) => (
                                    <LiMini
                                        onClick={() => {
                                            setCampusPk(item.id);
                                            // setSearchTerm(item.name);
                                            setCampusName(item.name);
                                            setCampusDropDown(!campusDropDown);
                                        }}
                                    >
                                        <p>{item.name}</p>
                                    </LiMini>
                                ))}
                            </UlMini>
                        </ClassDropDown>
                    </Popup>
                </CoverSet>
            </TopFilter>
        </>
    );
}

export default CampusFilter;
const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const CoverSet = styled.div``;
const Filter = styled.div`
    display: flex;
    align-items: center;
    background: #1b1c1f;
    border: 1px solid #26272a;
    padding: 6px 12px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
`;

const FilterText = styled.div`
    margin-right: 5px;
`;
const FIlterImage = styled.div`
    & img {
        width: 12px;
    }
`;

const Date = styled.div`
    text-align: center;
    background: #161618;
    border: 1px solid #26272a;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    .react-datepicker__day:hover {
        border: 1px solid #fff !important;
        color: #fff !important;
        background-color: red !important;
    }
    input {
        color: #fff;
        width: 90%;
        height: 40px;
    }
`;
const SearchImage = styled(Link)`
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const Popup = styled.div`
    font-size: 14px;
    color: #fff;
    width: 250px;
    padding: 10px;
    position: absolute;
    right: 17px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
    border: 1px solid #26272a;
    &.dropdown {
        opacity: 1;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.5);
        margin-top: 10px;
        overflow: visible;
        background: #1b1c20;
        max-height: 100vh;
        transition: 0.5s ease;
        border-radius: 5px;
    }
`;

const GraduationStatus = styled.div`
    position: relative;
    border-radius: 10px;
    height: 20px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-family: "gordita_regular";
    &.district {
        color: #fff;
    }
    @media all and (max-width: 1280px) {
        width: 100%;
    }
    /* @media all and (max-width: 980px) {
        width: 48%;
    } */
    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 480px) {
        width: 100%;
        margin-bottom: 36px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
        border: 1px solid #e6e6e6;
        &:hover {
            border: 1px solid #0fa76f;
            border-radius: 10px;
        }
    }
`;
const UlMini = styled.ul`
    /* background-color: #1c1b20; */
    font-size: 14px;
    width: 100%;
    height: 100%;
    font-family: "gordita_regular";
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    scroll-behavior: smooth;
    ::scrollbar-color {
        color: red;
    }
`;
const LiMini = styled.li`
    padding: 10px;
    background-color: #161518;
    border-radius: 5px;
    margin: 4px;
    :hover {
        color: #fff;
    }
    :last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 480px) {
        padding: 8px;
    }
    p {
        font-size: 14px;
        color: #fff;
        :hover {
            color: #fe673a;
        }
        @media all and (max-width: 768px) {
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
const ClassDropDown = styled.div`
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    height: 250px;
    left: 0;
    /* overflow: hidden; */
    background-color: #1c1b20;
    padding: 3px;
    top: 62px;
    border-radius: 8px;
    display: none;

    &.active {
        display: block;
    }
    @media all and (max-width: 1280px) {
        top: 76%;
        left: 0px;
    }
    @media all and (max-width: 1080px) {
        height: 210px;
    }
    @media all and (max-width: 400px) {
        top: 100%;
        padding: 2px;
    }
`;

const Error = styled.p`
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: -40%;
    right: 0;
    cursor: auto;
    & .tidios {
        color: #14a62a;
        font-family: "gordita_medium";
        text-decoration: underline;
        cursor: pointer;
    }
    @media all and (max-width: 480px) {
        font-size: 11px;
    }
`;
