import React from "react";
import styled from "styled-components";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function UserVerifyModal({
    isModal,
    setModal,
    wrapperRef,
    existingData,
    handleSubmit,
    isLoading,
    setLoading,
}) {
    return (
        <>
            <BackContainer>
                {isModal ? (
                    <Overlay
                        onClick={() => setModal(false)}
                        style={{ display: isModal ? "block" : "none" }}
                    ></Overlay>
                ) : (
                    ""
                )}
                <Cover style={{ transform: isModal && "scale(1,1)" }}>
                    <Modal ref={wrapperRef}>
                        <Container>
                            {/* <Headtitle>
                                Hai {existingData?.name} <span>👋 </span>
                            </Headtitle> */}
                            <MainTitle>
                                An account already exists in this number{" "}
                                <small>{existingData?.phone}</small>.<br />
                                Would you like to proceed using the existing
                                password?
                            </MainTitle>
                            <ButtonDiv>
                                <Button
                                    className="pass"
                                    onClick={() => {
                                        handleSubmit(true);
                                        setLoading("change-password");
                                    }}
                                >
                                    {isLoading === "change-password" ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Change password"
                                    )}
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleSubmit(false);
                                        setLoading("continue");
                                    }}
                                >
                                    {isLoading === "continue" ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Continue"
                                    )}
                                </Button>
                            </ButtonDiv>
                        </Container>
                    </Modal>
                </Cover>
            </BackContainer>
        </>
    );
}

export default UserVerifyModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    cursor: auto;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 35px;
    width: 100%;
    height: 100%;
    cursor: auto;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
    span {
        font-size: 28px;
    }
`;
const MainTitle = styled.h3`
    font-size: 16px;
    color: #fff;
    font-family: "gordita_regular" !important;
    font-weight: normal;
    line-height: 1.6em;
    small {
        font-family: "gordita_medium";
        font-size: 16px;
        color: #fe673a;
        margin-right: 2px;
    }
`;
const ButtonDiv = styled.div`
    display: flex;
    justify-content: right;
`;
const Button = styled.div`
    padding: 10px 20px;
    cursor: pointer;
    background: #fe673a;
    color: #fff;
    border-radius: 6px;
    margin-left: 10px;
    font-family: "gordita_medium";
    width: 122px;
    &.pass {
        background: #000000;
        color: #fe673a;
        border: 1px solid #fe673a;
        width: 200px !important;
    }
`;
