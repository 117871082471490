import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SectionLoader from "../../helpers/SectionLoader";
import { Link, useSearchParams } from "react-router-dom";
import StudentCard from "./StudentCard";
import CampusFilter from "./CampusFilter";
import AdmissionModal from "./modal/AdmissionModal";
import CreateStudentModal from "../subscription_manager/modal/CreateStudentModal";
import SheetModal from "../subscription_manager/modal/SheetModal";
import TabMenu from "../../screens/students/includes/TabMenu";

function StudentList({
    students,
    isStudentLoading,
    status,
    isDeleteModal,
    setDeleteModal,
    isDeletePk,
    setDeletePk,
    isReload,
    setReload,
    handleList,
    isTaskPk,
    setTaskPk,
    isTaskDetails,
    setTaskDetails,
    handlePageClick,
    selectedTab,
    setSelectedTab,
    studentsCount
}) {
    // filtering;
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [searchWord, setSearchWord] = useState("");
    const [activeClick, setActiveClick] = useState("Filter");
    const [isDropdown, setDropdown] = useState(false);
    const program = searchParams.get("program");
    var today = new Date();
    const [isModal, setModal] = useState(false);
    const [isDownload, setDownload] = useState(false);

    // const filteredStudentDetails = students?.filter((student) =>
    //     student.name.toLowerCase().includes(searchWord.toLowerCase())
    // );

    //modal ouside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const handleInput = (e) => {
        setSearchWord(e.target.value);
    };
    useEffect(() => {
        if (searchWord) {
            setSearchParams({
                q: searchWord,
            });
        } else {
            let params = currentParams;
            delete params["q"];
            setSearchParams(params);
        }
    }, [searchWord]);

    return (
        <>
            <FilterContainer>
                <TextArea>
                    {status === "active" ? "Active Students List" : "Students"}
                </TextArea>
                <FilterArea>
                    <SearchContainer>
                        <SearchBar>
                            <InputField
                                placeholder="Search name"
                                onChange={(e) => setSearchWord(e.target.value)}
                                value={searchWord}
                                maxLength={30}
                            />
                        </SearchBar>
                        <SearchImageContainer to="">
                            <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg" />
                        </SearchImageContainer>
                    </SearchContainer>
                    <CreateStudentModal
                        isModal={isModal}
                        setModal={setModal}
                        isReload={isReload}
                        setReload={setReload}
                    />
                    <SheetModal
                        isDownload={isDownload}
                        setDownload={setDownload}
                        isTaskPk={isTaskPk}
                        setTaskPk={setTaskPk}
                        isTaskDetails={isTaskDetails}
                        setTaskDetails={setTaskDetails}
                    />

                    <AddBtn onClick={() => setModal(true)}>
                        <span>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-10-2023/white-plus.svg"
                                alt="Add Admission"
                            />
                        </span>
                        Add Students
                    </AddBtn>
                    <AddBtn
                        onClick={() => {
                            setDownload(true);
                        }}
                    >
                        Download
                    </AddBtn>
                </FilterArea>
            </FilterContainer>
            <TabMenu setSelectedTab={setSelectedTab} selectedTab={selectedTab} studentsCount={studentsCount} />
            <MainContainer>
                <Cover>
                    <Category>
                        <Item type="student_sat_id">ID</Item>
                        <Item type="name">Name</Item>
                        <Item type="contact">Contact</Item>
                        <Item type="date">Joined Date</Item>
                        <Item type="action">Action</Item>
                    </Category>
                    {isStudentLoading ? (
                        <SectionLoader />
                    ) : (
                        <>
                            {students.length > 0 ? (
                                students.map((item, index) => (
                                    <div
                                        className="card-container"
                                        key={item.id}
                                    >
                                        <StudentCard
                                            student={item}
                                            isDeleteModal={isDeleteModal}
                                            setDeleteModal={setDeleteModal}
                                            isDeletePk={isDeletePk}
                                            setDeletePk={setDeletePk}
                                        />
                                    </div>
                                ))
                            ) : (
                                <ImageBox>
                                    <CardImage
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                                        alt="Image"
                                    />
                                </ImageBox>
                            )}
                        </>
                    )}
                </Cover>
            </MainContainer>
        </>
    );
}

export default StudentList;

const MainContainer = styled.div`
    border: 1px solid #26272a;
    border-radius: 5px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Cover = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1c20;
    color: #fff;
    min-width: 1300px;
    .card-container {
        border-bottom: 1px solid #000;
        :last-child {
            border-bottom: 0px solid #000;
        }
    }
`;
const Category = styled.div`
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
`;
const Item = styled.li`
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    font-family: "gordita_medium";
    width: ${({ type }) =>
        type === "student_sat_id"
            ? "10%"
            : type === "name"
            ? "20%"
            : type === "contact"
            ? "20%"
            : type === "date"
            ? "20%"
            : type === "action"
            ? "20%"
            : ""};
    list-style: none;
    display: ${({ type }) => (type === "action" ? "flex" : "")};
    justify-content: ${({ type }) => (type === "action" ? "end" : "")};
    &:last-child {
        text-align: center;
    }
    &:nth-child(3) {
        width: 18%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 20px 0 10px; */
    margin-bottom: 10px;
    @media all and (max-width: 460px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const Text = styled.div`
    font-size: 12px;
`;
const FilterImageContainer = styled.div`
    margin-left: 10px;
`;
const FilterImg = styled.img``;
const Scroll = styled.div`
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const GroupImg = styled.img`
    margin-left: 10px;
    width: 16px;
    cursor: pointer;
`;
const TextArea = styled.div`
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 460px) {
        margin-bottom: 15px;
    }
`;
const Main = styled.div``;
const FilterArea = styled.div`
    display: flex;
    color: #fff;
    align-items: center;
    padding: 5px;
    cursor: pointer;
`;
const DownloadBtn = styled.div`
    display: flex;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 8px;
    background: #fe673a;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-left: 12px;
    span {
        display: inline-block;
        width: 20px;
        height: 20px;
        img {
            width: 100%;
            display: inline-block;
        }
    }
`;
const AddBtn = styled.div`
    display: flex;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 8px;
    background: #fe673a;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    margin-left: 12px;
    span {
        display: inline-block;
        width: 18px;
        height: 18px;
        img {
            width: 100%;
            display: inline-block;
        }
    }
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #1b1c20;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled(Link)`
    background: #1b1c20;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    padding: 10px 0;
    cursor: pointer;
    img {
        with: 100%;
        display: block;
    }
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;

const ImageBox = styled.div`
    margin: 0 auto;
    width: 20%;
    margin-top: 100px;
    height: calc(100% - (-50px));
    @media all and (max-width: 980px) {
        width: 200px;
    }
`;
const CardImage = styled.img`
    width: 100%;
    display: block;
`;
