import React, { useState, useEffect, useRef, useContext } from "react";
import styled, { keyframes } from "styled-components";
import $ from "jquery";
import { Context } from "../../../contexts/Store";
import { accountsConfig } from "../../../../axiosConfig";

function SheetModal({ isDownload, setDownload, isTaskDetails }) {
    const [field, setField] = useState("");
    const [userData, setUserData] = useState({});

    const { state } = useContext(Context);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    /// out side click
    useEffect(() => {
        if (isDownload) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isDownload]);

    // ----------- api connection for download sheet -------------

    const handleDownload = async (isTaskPk) => {
        try {
            const response = await accountsConfig.get(
                `api/v1/users/techatschool-download-student-credentials/${isTaskPk}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    responseType: "blob",
                }
            );

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `student-list.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(
                `Error downloading and converting file for taskPk ${isTaskPk}:`,
                error
            );
        }
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDownload(false);
                    setField("");
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <BackContainer>
            {isDownload ? (
                <Overlay
                    onClick={() => setDownload(false)}
                    style={{ display: isDownload ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isDownload && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Header>
                            <Title>
                                <TitleImage>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2023/admin.svg"
                                        alt="Admission"
                                    />
                                </TitleImage>
                                <Headtitle>Admission</Headtitle>
                            </Title>
                            <Closeicon onClick={() => setDownload(false)}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Icon"
                                />
                            </Closeicon>
                        </Header>
                        <TabContent>
                            <>
                                <FormBox>
                                    <LabelWrapper>
                                        <TableContainer>
                                            <Table>
                                                <thead>
                                                    <TableRow>
                                                        <TableHeader>
                                                            SI No
                                                        </TableHeader>
                                                        <TableHeader>
                                                            File Name
                                                        </TableHeader>
                                                        <TableHeader>
                                                            Action
                                                        </TableHeader>
                                                    </TableRow>
                                                </thead>
                                                <>
                                                    <tbody>
                                                        {isTaskDetails.length >
                                                        0 ? (
                                                            isTaskDetails.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <TableRow
                                                                        key={index}
                                                                    >
                                                                        <TableCell>
                                                                            {index +
                                                                                1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.file
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.status ===
                                                                            "pending" ? (
                                                                                <DisabledButton className="pending">
                                                                                    Pending
                                                                                </DisabledButton>
                                                                            ) : item.status ===
                                                                              "failed" ? (
                                                                                <FailedButton>
                                                                                    Failed
                                                                                </FailedButton>
                                                                            ) : (
                                                                                <DownloadButton
                                                                                    onClick={() =>
                                                                                        handleDownload(
                                                                                            item.pk
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Download
                                                                                </DownloadButton>
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )
                                                        ) : (
                                                            <p>
                                                                No tasks
                                                                available.
                                                            </p>
                                                        )}
                                                    </tbody>
                                                </>
                                            </Table>
                                        </TableContainer>
                                    </LabelWrapper>
                                </FormBox>
                                <CancelButtonBox
                                    onClick={() => setDownload(false)}
                                >
                                    <CreateButton>Cancel</CreateButton>
                                </CancelButtonBox>
                            </>
                        </TabContent>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default SheetModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const FormBox = styled.div``;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 35px;
    width: 100%;
    height: 100%;
    cursor: auto;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Header = styled.div`
    border-bottom: 2px solid #26272a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
`;

const Headtitle = styled.p`
    font-size: 18px;
    margin-left: 10px;
    color: #fff;
    font-family: gordita_medium;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const TitleImage = styled.div`
    width: 28px;
    height: 28px;
    margin-right: 12px;
    img {
        width: 100%;
        display: block;
    }
`;

const TabContent = styled.div`
    padding: 20px 0;
    background: #1b1c1f;
`;
const LabelWrapper = styled.div`
    display: flex;
    height: 324px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
        scroll-behavior: smooth;
    }
`;
const TableContainer = styled.div`
    width: 100%;
`;

const Table = styled.table`
    width: 100%;
`;

const TableHeader = styled.th`
    padding: 8px;
    font-size: 15px;
    text-align: left;
`;

const TableRow = styled.tr`
    background: #1b1c1f;
`;

const TableCell = styled.td`
    padding: 8px;
    font-size: 16px;
    background: #101113;
    overflow-x: scroll;
    max-width: 310px;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const DownloadButton = styled.button`
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #fe673a;
    color: #fe673a;
    background: #2b2b30;
    /* hover */
    &:hover {
        opacity: 0.7;
        transition: 0.3s;
    }
`;
const DisabledButton = styled.button`
    padding: 5px 16px;
    cursor: not-allowed;
    border-radius: 4px;
    border: 1px solid #c81f1f;
    color: #c81f1f;
    background: #2b2b30;
`;
const FailedButton = styled.button`
    padding: 5px 23px;
    cursor: not-allowed;
    border-radius: 4px;
    border: 1px solid #c81f1f;
    color: #c81f1f;
    background: #2b2b30;
`;
const CreateButton = styled.button`
    color: #fff;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    border: 1px solid #fe673a;
`;
const CancelButtonBox = styled.div`
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;

    &.disabled div {
        cursor: not-allowed;
    }
    @media all and (max-width: 480px) {
        margin-top: 15px;
    }
`;
const NoTaskDiv = styled.div`
    padding: 12px;
    color: rgb(159, 159, 159);
`;
