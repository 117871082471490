import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";


function TabMenu({setSelectedTab, selectedTab, studentsCount}) {

    return (
        <MainContainer>
            <Items>
                <Item
                    activeClassName={selectedTab == null ? "active" : null}
                    onClick={() => setSelectedTab(null)}
                >
                    All
                </Item>
                {Object.entries(studentsCount).map(([key, value]) => {
                    
                    if (key === "jobsure_campus" && value > 0) {
                        return (
                            <Item
                                key="jobsure-campus" // Unique key for rendering
                                activeClassName={selectedTab === "jobsure-campus" ? "active" : null}
                                onClick={() => setSelectedTab("jobsure-campus")}
                            >
                                Jobsure
                            </Item>
                        );
                    }

                    if (key === "tech_at_school" && value > 0) {
                        return (
                            <Item
                                key="tech-at-school" // Unique key for rendering
                                activeClassName={selectedTab === "tech-at-school" ? "active" : null}
                                onClick={() => setSelectedTab("tech-at-school")}
                            >
                                Tech at school
                            </Item>
                        );
                    }

                    // Return null if no conditions are met to avoid rendering anything
                    return null;
                })}
            </Items>
            <hr />
        </MainContainer>
    );
}

export default TabMenu;
const MainContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Items = styled.ul`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px;
    min-width: 1100px;
`;
const Item = styled.button`
    padding: 8px 20px;
    font-size: 16px;
    color: #fff;
    font-family: "gordita_medium";
    transition: 1s ease;
    margin-right: 20px;
    cursor: pointer;
    color: ${props => props.activeClassName == "active" ? "#f7684c" : null};
    :last-child {
        margin-right: 0;
    }
`;
