import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SteypHelmet from "../../helpers/SteypHelmet";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import StudentList from "../../includes/students/StudentList";
import StudentDeleteModal from "../../includes/students/modal/StudentDeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnquiresList from "../../includes/students/EnquiresList";

function Enquries() {
    const [students, setStudents] = useState([]);
    const [isStudentLoading, setStudentLoading] = useState(false);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [campusStudentId, setCampusStudentId] = useState("");
    const [isDeletePk, setDeletePk] = useState("");
    const [isReload, setReload] = useState(false);
    const [toggleCampus, setToggleCampus] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [isTaskPk, setTaskPk] = useState(null);
    const [isTaskDetails, setTaskDetails] = useState([]);

    // const [userData, setUserData] = useState({});

    const {
        state: {
            user_data: { access_token, campus_pk },
        },
    } = useContext(Context);

    const { state } = useContext(Context);
    const role = state.user_data.role[0];

    const status = searchParams.get("status");

    const getSearchParams = () => {
        const filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        return filterParams;
    };

    const showSuccessNotification = () => {
        toast.info("Student removed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    // -----------------------students list api --------------------------
    const fetchStudents = () => {
        const params = getSearchParams();
        setStudentLoading(true);

        {
            if (role === "campus") {
                accountsConfig
                    .get("/api/v1/users/techatschool/listing-leads-data/", {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                        params: params,
                    })
                    .then((response) => {
                        let { StatusCode, data, pagination_data } =
                            response.data;
                        if (StatusCode === 6000) {
                            setStudents(data);
                            setPagination(pagination_data);
                            setLoading(false);
                            setStudentLoading(false);
                        } else {
                            setStudentLoading(false);
                            setLoading(false);
                            setStudents([]);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setStudentLoading(false);
                        setLoading(false);
                    });
            }
        }
    };

    //---------------------student delete api-----------------------------

    const deleteStudent = async () => {
        setDeleteLoader(true);

        try {
            const response = await accountsConfig.post(
                `api/v1/users/techatschool/remove-student/${isDeletePk}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            const { StatusCode } = response.data;
            if (StatusCode === 6000) {
                setReload(true);
                setDeletePk("");
                setDeleteModal(false);
                showSuccessNotification();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setDeleteLoader(false);
        }
    };

    //---------------------students list for dashboard click----------------

    const [paramsStudents, setParamsStudents] = useState([]);

    //----------------------------------------------------------------------
    const [isCard, setCard] = useState(false);
    useEffect(() => {
        let params = getSearchParams();
        fetchStudents();
        setCard(true);
        setReload(false);
    }, [searchParams, currentPage, isReload]);

    const [isEdit, setEdit] = useState(false);

    // ------------ api for list of sheets ---------------

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await accountsConfig.get(
                    `api/v1/users/techatschool/tasks/${campus_pk}`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );

                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTaskDetails(data);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching task details:", error);
            }
        };

        fetchData();
    }, [isReload]);

    // reload page

    return (
        <>
            <SteypHelmet title="Enquiries" />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <StudentDeleteModal
                deleteStudent={deleteStudent}
                isDeleteModal={isDeleteModal}
                setDeleteModal={setDeleteModal}
                deleteLoader={deleteLoader}
                isDeletePk={isDeletePk}
                setDeletePk={setDeletePk}
            />
            <Container>
                <StdTotal>
                    <EnquiresList
                        students={students}
                        isStudentLoading={isStudentLoading}
                        setCurrentPage={setCurrentPage}
                        status={status}
                        setCampusStudentId={setCampusStudentId}
                        setToggleCampus={setToggleCampus}
                        setEdit={setEdit}
                        isEdit={isEdit}
                        isLoading={isLoading}
                        pagination={pagination}
                        handlePageClick={handlePageClick}
                        setDeleteModal={setDeleteModal}
                        isDeleteModal={isDeleteModal}
                        setDeletePk={setDeletePk}
                        isReload={isReload}
                        setReload={setReload}
                        isTaskPk={isTaskPk}
                        setTaskPk={setTaskPk}
                        isTaskDetails={isTaskDetails}
                        setTaskDetails={setTaskDetails}
                    />
                    {!isLoading && pagination?.total_pages > 1 && (
                        <PaginationContainer>
                            <PaginationText>
                                Showing students {pagination?.first_item} -{" "}
                                {pagination?.last_item} of{" "}
                                {pagination?.total_items}
                            </PaginationText>
                            <ReactPaginate
                                previousLabel={"< Prev"}
                                nextLabel={"Next >"}
                                pageCount={pagination?.total_pages}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                        </PaginationContainer>
                    )}
                </StdTotal>
            </Container>
        </>
    );
}

export default Enquries;

const Container = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    h3 {
        color: #fff;
        font-size: 18px;
        font-family: "gordita_medium";
        margin-bottom: 20px;
    }
`;
const StdList = styled.div`
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #26272a;
`;
const StdTotal = styled.div``;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
const MiniCover = styled.select`
    border: 1px solid #26272a;
    background: #1b1c1f;
    width: 100%;
    min-height: 45px;
    color: #fff;
    padding: 0 15px;
`;
const List = styled.option`
    border-bottom: 1px solid #26272a;
    background: #1b1c1f !important;
`;
