import React, { useState, useEffect, useContext } from "react";
import {
    Navigate,
    Route,
    Routes,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "./AppRouter";
import AuthRouter from "./authentication/AuthRouter";
import { accountsConfig } from "../../../axiosConfig";

const MainRouter = ({ health_user }) => {
    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    const [isLoading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();

    useEffect(() => {
        if (access_token) {
            accountsConfig
                .post(
                    "api/v1/users/check-user-health/",
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {})
                .catch((err) => {
                    if (err.response.status === 401) {
                        localStorage.clear();
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                is_loading: true,
                                is_verified: false,
                                access_token: "",
                                name: "",
                                roles: [],
                            },
                        });
                        navigate("/auth/login/?next=/");
                    }
                });
        }
    }, [access_token]);

    async function fetchUserData() {
        let promise = new Promise((resolve, reject) => {
            let user_data = localStorage.getItem("user_data");
            user_data = JSON.parse(user_data);

            dispatch({
                type: "UPDATE_USER_DATA",
                user_data: { ...user_data, is_loading: true },
            });

            setTimeout(() => {
                resolve("done!");
                setLoading(false);
            }, 500);
        });

        let result = await promise;
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        async function fetchStudentData() {
            let promise = new Promise((resolve, reject) => {
                let student_data = localStorage.getItem("student_data");
                student_data = JSON.parse(student_data);

                dispatch({
                    type: "UPDATE_STUDENT_DETAILS",
                    student_data: student_data,
                });

                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }

        fetchStudentData();
    }, []);

    useEffect(() => {
        dispatch({
            type: "UPDATE_SEARCH_PARAMS",
            payload: currentParams,
        });
    }, []);

    return isLoading ? (
        <SectionLoader />
    ) : (
        <Routes>
            <Route
                path="auth/*"
                element={
                    <AuthRoute>
                        <AuthRouter />
                    </AuthRoute>
                }
            />
            <Route
                path="/*"
                element={
                    <PrivateRoute>
                        <AppRouter />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default MainRouter;
