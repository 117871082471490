import React from "react";
import styled from "styled-components";

function Payments() {
    return <Container>Payment</Container>;
}

export default Payments;

const Container = styled.div`
    h3 {
        color: #fff;
        font-size: 18px;
        font-family: "gordita_medium";
        margin-bottom: 20px;
    }
`;
