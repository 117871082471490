import React, { lazy, useContext } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../../contexts/Store";
//components
const StudentInnerRouter = lazy(() => import("./StudentInnerRouter"));
const Students = lazy(() => import("../../../screens/students/Students"));
const PrimeAboutStudents = lazy(() =>
    import("../../../screens/students/PrimeAboutStudents")
);

function StudentRouter() {
    const { state } = useContext(Context);
    const role = state.user_data.role[0];
    return (
        <>
            <Container id="main">
                <Routes>
                    <Route path="/" element={<Students />} />
                    <Route
                        path="/:student_id/*"
                        element={<StudentInnerRouter />}
                    />
                </Routes>
            </Container>
        </>
    );
}

export default StudentRouter;

const Container = styled.div``;
