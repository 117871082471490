import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import AdmissionTab from "./AdmissionTab";

function AdmissionModal({ isModal, setModal }) {
    const handleModal = () => {
        setModal(false);
    };
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <BackContainer>
            {isModal ? <Overlay></Overlay> : ""}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Top>
                            <Head>
                                <HeadIcon>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2023/admin.svg"
                                        alt="Admission"
                                    />
                                </HeadIcon>
                                <Headtitle>Admission</Headtitle>
                            </Head>
                            <Closeicon onClick={handleModal}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Icon"
                                />
                            </Closeicon>
                        </Top>
                        <AdmissionTab />
                    </Container>
                    {/* <FormBtnDiv>
                        <CancelButton onClick={handleModal}>
                            Cancel
                        </CancelButton>
                        <Button>Submit</Button>
                    </FormBtnDiv> */}
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default AdmissionModal;

const BackContainer = styled.div`
    cursor: default;
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    border-bottom: 2px solid #26272a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
`;
const Head = styled.div`
    display: flex;
    align-items: center;
`;
const HeadIcon = styled.div`
    width: 28px;
    height: 28px;
    margin-right: 12px;
    img {
        width: 100%;
        display: block;
    }
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const TabsWrapper = styled.div`
    display: flex;
    width: 100%;
`;
const Form = styled.form``;
const FormLabel = styled.label``;
const FormInput = styled.input`
    display: flex;
    height: 60px;
    width: 100%;
    padding: 10px 24px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid #333;
    background: #161619;
`;
const AppWrapper = styled.div`
    width: 100%; /* Ensures the content takes 100% width */
    display: flex;
`;

const TabContentWrapper = styled.div`
    width: ${(props) =>
        props.active ? "100%" : "auto"}; /* Width based on active state */
    margin-top: 16px;
    display: ${(props) => (props.active ? "block" : "none")};
`;
const Button = styled.button`
    color: #fff;
    cursor: pointer;
    background: #525252;
    width: fit-content;
    padding: 10px 36px;
    display: inline-block;
    border-radius: 4px;
    &:hover {
        opacity: 80%;
    }
`;
const CancelButton = styled.button`
    color: #fff;
    cursor: pointer;
    width: fit-content;
    border: 1px solid #fe673a;
    padding: 9px 36px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 12px;
    &:hover {
        opacity: 80%;
    }
`;
const FormBtnDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 24px 35px;
`;
