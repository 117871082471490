import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";

const AdmissionTab = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [hide, setHide] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(100);
    const [receivedFiles, setReceivedFiles] = useState([]);

    const passwordfocus = useRef();

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handlePasswordShow = () => {
        setHide(!hide);
    };

    const handleFileUpload = () => {
        // Simulate file upload delay for 2 seconds
        setUploading(true);

        const interval = setInterval(() => {
            if (uploadProgress < 100) {
                setUploadProgress((prevProgress) => prevProgress + 10);
            } else {
                clearInterval(interval);
                setUploading(false); // Upload process stops here
                setUploadProgress(100);
                // Add the uploaded file to the list of received files
                setReceivedFiles([...receivedFiles, "file_name_here"]);
            }
        }, 200);
    };

    return (
        <>
            <TabWrapper>
                <TabLabel
                    active={activeTab === 0}
                    onClick={() => handleTabClick(0)}
                >
                    Add Student
                </TabLabel>
                <TabLabel
                    active={activeTab === 1}
                    onClick={() => handleTabClick(1)}
                >
                    Import CSV
                </TabLabel>
            </TabWrapper>
            <TabContent>
                {activeTab === 0 ? (
                    <FormWrapper>
                        <UserInput>
                            <Label htmlFor="phone">Phone Number</Label>
                            <InputField
                                type="text"
                                id="name"
                                placeholder="Enter phone number"
                            />
                        </UserInput>
                        <UserInput>
                            <Label htmlFor="name">Full Name</Label>
                            <InputField
                                type="text"
                                id="name"
                                placeholder="Enter full name"
                            />
                        </UserInput>
                        <UserInput>
                            <Label htmlFor="password">Password</Label>
                            <InputField
                                type={hide ? "password" : "text"}
                                id="password "
                                placeholder="Enter Password"
                                ref={passwordfocus}
                                maxLength={16}
                            />
                            <EyeSpan>
                                <Eye
                                    onClick={handlePasswordShow}
                                    style={{ cursor: "pointer" }}
                                    src={
                                        hide
                                            ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/hide.svg"
                                            : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/eye.svg"
                                    }
                                />
                            </EyeSpan>
                        </UserInput>
                        {/* <FormBtnDiv>
                        <CancelButton>Cancel</CancelButton>
                        <Button>Submit</Button>
                    </FormBtnDiv> */}
                    </FormWrapper>
                ) : (
                    //------------------------------------------------

                    <>
                        <LabelWrapper active={true} htmlFor="fileUpload">
                            {uploading ? (
                                <LoadingSpinner>
                                    <Spinner>
                                        <SpinnerInner />
                                    </Spinner>
                                    <LoadingText>{uploadProgress}%</LoadingText>
                                </LoadingSpinner>
                            ) : (
                                <>
                                    <BrowseIcon>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2023/browse-icon.svg"
                                            alt="File"
                                        />
                                    </BrowseIcon>
                                    <BrowseLabel1>
                                        Drag and Drop photo here.
                                    </BrowseLabel1>
                                    <BrowseLabel>Browse file</BrowseLabel>
                                    <ReceivedFiles>
                                        {receivedFiles.map((file, index) => (
                                            <div key={index}>{file}</div>
                                        ))}
                                    </ReceivedFiles>
                                </>
                            )}
                        </LabelWrapper>
                    </>
                )}
                <FileInput
                    active={activeTab === 1}
                    type="file"
                    id="fileUpload"
                    onChange={handleFileUpload}
                    disabled={uploading}
                />
            </TabContent>
        </>
    );
};

export default AdmissionTab;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #e76037;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SpinnerInner = styled.div`
    border: 10px solid rgba(0, 0, 0, 0.1);
    border-top: 10px solid #e76037;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: ${spin} 3s linear infinite;
`;

const LoadingText = styled.div`
    margin-top: 10px;
`;

const TabWrapper = styled.div`
    display: flex;
    width: 50%;
`;

const TabLabel = styled.div`
    flex: 1;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    color: ${(props) => (props.active ? "#E76037" : "")};
    border-bottom: 2px solid
        ${(props) => (props.active ? "#E76037" : "#26272a")};
    transition: border-color 0.3s ease;
    &:hover {
        opacity: 90%;
    }
`;

const TabContent = styled.div`
    padding: 20px 0;
    background: #1b1c1f;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    input {
        display: flex;
        height: 60px;
        padding: 10px 24px;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid #333;
        background: #161619;
        color: #fff;
    }
    ::placeholder {
        color: #bcbcbc;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
const Label = styled.label`
    color: #7b7b7b;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
`;
const FileInput = styled.input`
    display: none;
`;

const LabelWrapper = styled.label`
    display: flex;
    height: 324px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    cursor: pointer;
`;
const Button = styled.button`
    color: #fff;
    cursor: pointer;
    background: #525252;
    width: fit-content;
    padding: 10px 36px;
    display: inline-block;
    border-radius: 4px;
    &:hover {
        opacity: 80%;
    }
`;
const CancelButton = styled.button`
    color: #fff;
    cursor: pointer;
    width: fit-content;
    border: 1px solid #fe673a;
    padding: 9px 36px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 12px;
    &:hover {
        opacity: 80%;
    }
`;
const FormBtnDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 24px;
`;
const EyeSpan = styled.span`
    max-width: 25px;
    max-height: 25px;
    display: block;
    position: absolute;
    right: 20px;
    top: 54px;
`;
const Eye = styled.img`
    max-width: 100%;
    display: block;
`;
const InputField = styled.input`
    width: 100%;
    font-size: 18px;
    color: #fff;
    font-family: "gordita_regular";
    margin-top: 12px;

    &:focus-within {
        border-color: #fe673a;
    }
    @media (max-width: 480px) {
        font-size: 16px;
    }
`;
const UserInput = styled.div`
    position: relative;
`;
const BrowseIcon = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
const BrowseLabel1 = styled.h6`
    color: #9f9f9f;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
`;
const BrowseLabel = styled.h5`
    background: #161619;
    color: #fe673a;
    font-size: 16px;
    text-decoration-line: underline;
    cursor: pointer;
`;
const ReceivedFiles = styled.div``;
