const ChiefReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_STUDENT_ACADEMICS":
            const student_academics = {
                ...state.student_academics,
                ...action.student_academics,
            };
            localStorage.setItem(
                "student_academic",
                JSON.stringify(student_academics)
            );
            return {
                ...state,
                student_academics: student_academics,
            };
        case "UPDATE_STUDENT_DETAILS":
            const student_data = {
                ...state.student_data,
                ...action.student_data,
            };
            localStorage.setItem("student_data", JSON.stringify(student_data));
            return {
                ...state,
                student_data: student_data,
            };
        case "UPDATE_USER_ID":
            return {
                ...state,
                user_id: action.user_id,
            };
        case "UPDATE_SUPPORT_SIDEBAR":
            return {
                ...state,
                support_sidebar: action.support_sidebar,
            };
        case "UPDATE_STUDENTS":
            return {
                ...state,
                students: action.students,
            };
        case "UPDATE_SEARCH_STUDENTS":
            return {
                ...state,
                search_results: action.search_results,
            };
        case "UPDATE_FILTER":
            return {
                ...state,
                isFilter: !state.isFilter,
            };
        case "UPDATE_SIDEBAR":
            return {
                ...state,
                isSideBar: action.isSideBar,
            };
        case "UPDATE_SEARCH_PARAMS":
            return {
                ...state,
                currentParams: action.payload,
            };
        case "UPDATE_CHAT_USER":
            return {
                ...state,
                chat_user: action.payload,
            };
        case "UPDATE_SUPPORT_STUDENT_LIST_MENU":
            return {
                ...state,
                isStudentList: action.isStudentList,
            };
        case "UPDATE_SUPPORT_EDIT_PROFILE":
            return {
                ...state,
                isEditProfile: action.isEditProfile,
            };
        case "UPDATE_EDITOR_VIEW":
            localStorage.setItem("isView", JSON.stringify(action.isView));
            return {
                ...state,
                isView: action.isView,
            };
        case "TOGGLE_PROFILE_MODAL":
            return {
                ...state,
                isProfileModel: !state.isProfileModel,
            };
        case "TOGGLE_NEW_UPDATES_MODAL":
            return {
                ...state,
                isNewUpdateModal: !state.isNewUpdateModal,
            };
        case "UPDATE_PROFILE_MODAL":
            return {
                ...state,
                profileModalType: action.profileModalType,
            };
        case "UPDATE_NEW_UPDATES_MODAL_TYPE":
            return {
                ...state,
                newUpdatesModalType: action.newUpdatesModalType,
            };
        case "UPDATE_MY_PROFILE_EDITING_DATA":
            const selectedEditingMyProfileData = {
                ...state.selectedEditingMyProfileData,
                ...action.selectedEditingMyProfileData,
            };
            return {
                ...state,
                selectedEditingMyProfileData:
                    action.selectedEditingMyProfileData,
            };
        case "TOGGLE_IS_PROFILE_UPDATE":
            return {
                ...state,
                is_profile_update: action.is_profile_update,
            };
        case "ACTIVE_PROFILE_MENU":
            return {
                ...state,
                active_profile_menu: action.active_profile_menu,
            };
        case "UPDATE_USER_PROFILE":
            return {
                ...state,
                user_profile: action.user_profile,
            };
        case "TOGGLE_PROFILE_LOADING":
            return {
                ...state,
                isUserDataLoading: !state.isUserDataLoading,
            };
        default:
            return state;
    }
};

export default ChiefReducer;
