import React, { lazy, useContext } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
const Enquiry = lazy(() => import("../../../screens/enquiry/Enquiry"));

function EnquiryRouter() {
    return (
        <Container id="main">
            <Routes>
                <Route path="/" element={<Enquiry />} />
            </Routes>
        </Container>
    );
}

export default EnquiryRouter;
const Container = styled.div``;
