import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/animations/loader.json";

export default function ButtonLoader({ joinedLoader }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <Lottie
            options={defaultOptions}
            height={joinedLoader ? "100%" : 20}
            width={20}
        />
    );
}
