import React, { lazy, useContext } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import Enquries from "../../../screens/students/Enquries";
//components


function EnquiesRouter() {
    const { state } = useContext(Context);
    const role = state.user_data.role[0];
    return (
        <>
            <Container id="main">
                <Routes>
                    <Route path="/" element={<Enquries />} />
                    {/* <Route
                        path="/:student_id/*"
                        element={<StudentInnerRouter />}
                    /> */}
                </Routes>
            </Container>
        </>
    );
}

export default EnquiesRouter;

const Container = styled.div``;
