import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function StudentDeleteModal({
    isDeleteModal,
    setDeleteModal,
    deleteLoader,
    deleteStudent,
}) {
    useEffect(() => {
        if (isDeleteModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isDeleteModal]);
    return (
        <BackContainer>
            {isDeleteModal ? (
                <Overlay
                    onClick={() => setDeleteModal(false)}
                    style={{ display: isDeleteModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isDeleteModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Top>
                            <Head>
                                <MainTitle className="gm">
                                    Are you sure you want to remove this
                                    student?
                                </MainTitle>
                                <Close>
                                    <CloseIcon
                                        onClick={() =>
                                            setDeleteModal(!isDeleteModal)
                                        }
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Close Icon"
                                    />
                                </Close>
                            </Head>
                        </Top>

                        <CancelContainer>
                            <CancelButton
                                onClick={() => setDeleteModal(!isDeleteModal)}
                            >
                                Cancel
                            </CancelButton>
                            {deleteLoader ? (
                                <VerifyButton>
                                    <ButtonLoader width={20} height={20} />
                                </VerifyButton>
                            ) : (
                                <>
                                    <VerifyButton
                                        onClick={() => {
                                            deleteStudent();
                                            // setDeleteModal(!isDeleteModal);
                                        }}
                                    >
                                        Yes
                                    </VerifyButton>
                                </>
                            )}
                        </CancelContainer>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default StudentDeleteModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 100vh;
    /* min-height: 230px; */
    /* height: ${({ textarea }) => (textarea ? "90vh" : "")}; */
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 105;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    box-shadow: 0px 3px 56px #00000048;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 600px;
    }
    @media all and (max-width: 700px) {
        width: 560px;
    }
    @media all and (max-width: 640px) {
        width: 430px;
    }
    @media all and (max-width: 600px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;

const BackContainer = styled.div``;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Top = styled.div`
    margin-bottom: 15px;
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #26272a;
    align-items: center;
    padding-bottom: 15px;
    @media all and (max-width: 480px) {
        padding-bottom: 10px;
    }
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Close = styled.span`
    cursor: pointer;
    width: 18px;
    @media all and (max-width: 640px) {
        width: 15px;
    }
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Heading = styled.p`
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;
const CancelContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const Paragraph = styled.p`
    margin-bottom: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
`;
const CancelButton = styled.button`
    padding: 10px 20px;
    cursor: pointer;
    color: #ff3030;
    border-radius: 6px;
    background-color: #fff;
    color: #000;
    font-family: "gordita_medium";
    width: 100px;
`;

const VerifyButton = styled.button`
    padding: 10px 20px;
    cursor: pointer;
    background: #fe673a;
    color: #fff;
    border-radius: 6px;
    margin-left: 10px;
    font-family: "gordita_medium";
    width: 100px;
`;
