import React, { useEffect } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import animationData from "../../../../assets/lotties/success-lottie.json";
import $ from "jquery";

export default function SuccessModal({
    isSuccessModal,
    setSuccessModal,
    isReload,
    setReload,
    setModal,
    isModal,
    sucessText,
    setSucessText,
}) {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {},
    };

    /// out side click
    useEffect(() => {
        if (isSuccessModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isSuccessModal]);

    return (
        <>
            <Overlay className={isSuccessModal ? "active" : ""}></Overlay>
            <Container className={isSuccessModal ? "active" : ""}>
                <SubContainer>
                    <Title>{sucessText ? sucessText : "Successful"}</Title>
                    <Lottie options={defaultOptions} height={300} width={300} />
                    <Continue
                        onClick={() => {
                            setSuccessModal((prev) => !prev);
                            setReload(true);
                            setSucessText("");
                        }}
                    >
                        Okay
                    </Continue>
                </SubContainer>
            </Container>
        </>
    );
}

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.2s ease-in;
    overflow: hidden;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    &.active {
        display: block;
    }
`;
const Container = styled.div`
    transform: scale(0, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    cursor: auto;

    &.active {
        transform: scale(1, 1);
    }
`;
const SubContainer = styled.div`
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 10px;
    box-shadow: 0px 3px 56px #00000048;
    padding: 20px 35px 35px 35px;
    width: 500px;
`;
const Title = styled.h6`
    font-size: 22px;
    color: #fff;
    font-family: gordita_medium;
    text-align: center;
`;
const Continue = styled.div`
    background-color: #f7684c;
    color: #fff;
    width: 150px;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer !important;
    margin: 0 auto;
`;
