import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CTooltip } from "@coreui/react";
import {
    getDateDifference,
    getDateStr,
    getDDMMYY,
    getFormatedStringFromDays,
} from "../../helpers/functions";

function StudentCard({
    student,
    setDeleteModal,
    isDeleteModal,
    item,
    setDeletePk,
}) {
    return (
        <StudentCards to={`${student.id}/about`}>
            <Item type="student_sat_id">#{student.user_id}</Item>
            <Item type="name">
                {student.name ? student.name.toLowerCase() : "--"}
            </Item>
            <Item type="contact"> {student.phone ? student.phone : "--"}</Item>
            <Item type="date">{getDateStr(student.date_added)}</Item>
            <Item type="action">
                <RemoveBtn
                    onClick={(e) => {
                        e.preventDefault();
                        setDeleteModal(!isDeleteModal);
                        setDeletePk(student.id);
                    }}
                >
                    Remove
                </RemoveBtn>
            </Item>
        </StudentCards>
    );
}

export default StudentCard;

const Span = styled.div`
    background: ${({ type }) =>
        type === "active"
            ? "#2E433F"
            : type === "expired"
            ? "#73414A"
            : "#514343"};
    padding: 10px;
    border-radius: 8px;
    color: ${({ type }) =>
        type === "active"
            ? "#14C25A"
            : type === "expired"
            ? "#E94C68"
            : "#fff"};
    font-size: 14px;
`;
const DaysPending = styled.span`
    color: #fff;
    line-height: 1;
`;
const StudentCards = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    padding: 20px;
    color: #fff;
    :last-child {
        border-bottom: 0px solid #000;
    }
`;
const Item = styled.li`
    text-align: left;
    list-style: none;
    text-transform: capitalize;
    font-size: 16px;
    width: ${({ type }) =>
        type === "student_sat_id"
            ? "10%"
            : type === "name"
            ? "20%"
            : type === "contact"
            ? "20%"
            : type === "date"
            ? "20%"
            : type === "action"
            ? "20%"
            : ""};
    display: ${({ type }) => (type === "action" ? "flex" : "")};
    justify-content: ${({ type }) => (type === "action" ? "end" : "")};
    &:last-child {
        text-align: center;
    }
    &:nth-child(3) {
        width: 18%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const RemoveBtn = styled.div`
    display: flex;
    width: 132px;
    height: 44px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    color: #ff3030;
`;

const Button = styled.div`
    border-radius: 20px;
    font-size: 14px;

    width: 10%;

    & span {
        background: ${(props) =>
            props.type === "active"
                ? "#232d2c"
                : props.type === "expired"
                ? "#37181e"
                : "#514343"};
        color: ${(props) =>
            props.type === "active"
                ? "#178045"
                : props.type === "expired"
                ? "#b03f5b"
                : "#fff"};
        padding: ${({ type }) =>
            type === "active" || type === "expired" || type === "others"
                ? "10px 16px"
                : ""};
        border-radius: 20px;

        display: flex;
        justify-content: center;
        position: relative;
    }
`;
const ItemName = styled.div`
    width: 80%;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        color: #fff;
        width: 100%;
        &.number {
            color: #fe673a;
            font-size: 14px;
        }
    }
`;
const EditCover = styled.div`
    display: flex;
    align-items: center;
    /* &.none {
		display: none;
	} */
    p {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #fff;
    }
`;

const ItemImageContainer = styled.div`
    margin-right: 10px;
`;
const ItemImage = styled.img``;
const ItemNumber = styled.div`
    width: 18%;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    & div {
        margin-right: 5px;
    }
    span {
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 21px;
        width: 30px;
        height: 30px;
        border: 1px solid #fe673a;
        padding: 6px;
        border-radius: 8px;
        cursor: pointer;
    }
    span img {
        width: 100%;
        display: block;
    }
    p {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
        font-size: 14px;
        color: #fff;
    }
`;
const ItemCategory = styled.div`
    width: 15%;
    font-size: 14px;
    font-weight: 800;
    color: ${({ type }) =>
        type === "techies-hub"
            ? "#4b9489"
            : type === "techies-club"
            ? "#448a6c"
            : type === "tech-degree" && "#7c7739"};
`;
const EditButton = styled.div`
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 21px;
    width: 44px;
    height: 44px;
    border: 1px solid #fe673a;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 12px;
    img {
        width: 70%;
        display: block;
    }
`;
const DeleteButton = styled.div`
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 21px;
    width: 30px;
    height: 30px;
    border: 1px solid #fe673a;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;

    img {
        width: 100%;
        display: block;
    }
`;
const AddButton = styled.div`
    cursor: pointer;
    width: 13%;
    margin: 0 auto 0px 24px;
    border: 1px solid #fff;
    padding: 4px;
    border-radius: 6px;
    &.none {
        display: none;
    }
    img {
        width: 100%;
        display: block;
    }
`;
