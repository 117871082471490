import React from "react";
import { Helmet } from "react-helmet";

const SteypHelmet = ({ title }) => {
    if (title) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${title}`} | Steyp</title>
                <meta
                    id="og-title"
                    property="og:title"
                    content={`${title} | Steyp`}
                />
            </Helmet>
        );
    } else {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>Steyp | Admin</title>
                <meta
                    id="og-title"
                    property="og:title"
                    content="Steyp is an EdTech company, a beginning of Digital University for industry 4.0, where one can find the relevant courses to upgrade skills from the top experts and gear up for the future advancements in technology, an initiative from Talrop."
                />
            </Helmet>
        );
    }
};

export default SteypHelmet;
