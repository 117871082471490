import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        is_loading: true,
        is_verified: false,
        access_token: "",
        name: "",
        roles: [],
    },
    isSideBar: false,
    currentParams: {},
    user_id: "",
    selected_country: {
        country_code: "IND",
        flag: "https://ddl0zi5h2jlue.cloudfront.net/media/countries/flags/download.png",
        name: "India",
        phone_code: "+91",
        web_code: "IN",
    },
    student_data: {
        user_id: "",
        student_name: "",
        phone: "",
        user_pk: "",
        student_sat_id: "",
        student_photo: "",
    },

    chat_user: {
        id: "",
        name: "",
        pk: "",
    },
    user_profile: {
        subscription_data: {},
        program: {},
    },
    // My Profile
    selectedEditingMyProfileData: {},
    newUpdatesModalType: "",
    isNewUpdateModal: false,

    student_academics: {
        student_id: "",
        id: "",
        name: "",
        phone: "",
        academic_history: [],
    },

    error: "",
    errorState: {
        isError: false,
        errorData: {
            status: 200,
            redirectLink: "",
            errorMessage: "An error occured, please try later",
        },
    },
    profileModalType: "",
    isStudentList: false,
    isEditProfile: false,
    isView: false,
    is_profile_update: false,
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
