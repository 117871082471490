import React, { useState, useEffect, Suspense, useContext } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
//routes

import ChiefRoute from "../routes/roles/ChiefRoute";

//routers
import ChiefRouter from "./roles/ChiefRouter";

//components

import SectionLoader from "../../helpers/SectionLoader";
import SideBar from "../../includes/sidebars/SideBar";
import TopBar from "../../includes/TopBar";

import { Context } from "../../contexts/Store";

function AppRouter() {
    const { state } = useContext(Context);
    const role = state.user_data.role[0];

    const [isExpand, setExpand] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const {
        state: { user_data },
    } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (user_data.uid) {
            setLoading(false);
        }
    }, [user_data]);

    return (
        <div>
            {role !== "support_engineer" && <SideBar setExpand={setExpand} />}
            {/* <SideBar setExpand={setExpand} /> */}
            <TopBar isExpand={isExpand} setExpand={setExpand} />
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <ChiefRoute>
                                <ChiefRouter />
                            </ChiefRoute>
                        }
                    />
                </Routes>
            </Suspense>
        </div>
    );
}

export default AppRouter;
